import(/* webpackMode: "eager", webpackExports: ["SettingsProvider"] */ "/home/runner/work/fomod-builder/fomod-builder/src/app/components/SettingsContext.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["MonacoDataContextProvider"] */ "/home/runner/work/fomod-builder/fomod-builder/src/app/fomod-monaco/client/MonacoDataContext.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/fomod-builder/fomod-builder/src/app/GlobalKeybinds.tsx");
;
import(/* webpackMode: "eager" */ "/home/runner/work/fomod-builder/fomod-builder/src/app/loaders/index.tsx");
;
import(/* webpackMode: "eager" */ "/home/runner/work/fomod-builder/fomod-builder/src/app/localization/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/fomod-builder/fomod-builder/src/app/tabbed-ui/Tabs.tsx");
